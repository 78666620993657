import React from "react";
import { Flex, Heading } from "theme-ui";
import IconButton from "./buttons/IconButton";
import BackIcon from "./icons/Back";
import CloseIcon from "./icons/Close";

type Properties = {
  readonly className?: string;
  readonly onBackRequest?: React.MouseEventHandler<Element>;
  readonly onCloseRequest?: React.MouseEventHandler<Element>;
};

// eslint-disable-next-line max-lines-per-function
const Header: React.FunctionComponent<React.PropsWithChildren<Properties>> = ({
  className,
  children,
  onBackRequest,
  onCloseRequest,
}) => {
  return (
    <Flex
      as="header"
      px={3}
      bg="accent-bg"
      sx={{
        borderRadius: [0, "primary"],
        justifyContent: "space-between",
        alignItems: "center",

        transition: "background-color 240ms ease-in",
      }}
      className={className}
    >
      {onBackRequest && (
        <IconButton
          tippyProps={{ placement: "left" }}
          onClick={onBackRequest}
          icon={BackIcon}
          label="Back"
        />
      )}

      <Heading sx={{ fontSize: 1, textTransform: "uppercase" }} as="h3">
        {children}
      </Heading>

      {onCloseRequest && (
        <IconButton
          tippyProps={{ placement: "top" }}
          onClick={onCloseRequest}
          icon={CloseIcon}
          label="Close"
        />
      )}
    </Flex>
  );
};

export default Header;
