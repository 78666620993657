import { Command } from "@styled-icons/feather/Command";
import { useState } from "react";
import { Button, Grid } from "theme-ui";
import TextLinkButton from "./buttons/TextLinkButton";
import MenuHeader from "./MenuHeader";
import InfoModal from "./modals/InfoModal";

const Controls = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <div>
      <TextLinkButton
        type="button"
        onClick={() => (isOpen ? setIsOpen(false) : setIsOpen(true))}
        sx={{ fontWeight: "normal", width: "100%" }}
      >
        <Command sx={{ width: 24, height: 24, strokeWidth: 2 }} /> Controls
      </TextLinkButton>
      <InfoModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <MenuHeader onCloseRequest={() => setIsOpen(false)}>
          Controls
        </MenuHeader>
        <Grid sx={{ overflow: "auto", maxHeight: "85vh" }}>
          <dl sx={{ padding: 2 }}>
            <dt>ctrl+c, cmd+c</dt>
            <dd>Copy the selected shapes.</dd>

            <dt>ctrl+v, cmd+v</dt>
            <dd>Paste the shapes copied previously.</dd>

            <dt>ctrl+x, cmd+x</dt>
            <dd>
              Cut the selected shapes by copying and removing from the design.
            </dd>

            <dt>ctrl+z, cmd+z</dt>
            <dd>Undo the previous action.</dd>

            <dt>ctrl+y, cmd+y</dt>
            <dd>Redo a previously undoed action.</dd>

            <dt>hold spacebar</dt>
            <dd>Panning mode activated.</dd>

            <dt>hold middle mouse</dt>
            <dd>Panning mode activated.</dd>

            <dt>esc</dt>
            <dd>Cancel many actions and menus.</dd>
          </dl>
        </Grid>
      </InfoModal>
    </div>
  );
};

export default Controls;
