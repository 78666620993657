/* eslint-disable max-lines-per-function */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid } from "theme-ui";
import { getCanvas } from "../design/canvas";
import { RootState } from "../lib/configureStore";
import SecondaryToolsContainer from "./SecondaryToolsContainer";
import SelectionContainer from "./selection/SelectionContainer";
import SettingsContainer from "./settings/SettingsContainer";
import ShapesContainer from "./shapes/ShapesContainer";
import ToolsContainer from "./tools/ToolsContainer";
import ViewportToolsContainer from "./ViewportToolsContainer";

const App = () => {
  const [hasSelection, setHasSelection] = useState(false);

  // Creation shape tools have a toolbar that sits on the bottom of the screen.
  // This is used to position it to fill the bottom on small screens
  const [isCreating, setIsCreating] = useState(false);

  const canvas = useSelector((state: RootState) => getCanvas(state.canvas));

  useEffect(() => {
    if (!canvas) {
      return;
    }

    const handleSelectionCreated = () => {
      setHasSelection(true);
    };

    const handleSelectionCleared = () => {
      setHasSelection(false);
    };

    canvas.on("selection:created", handleSelectionCreated);
    canvas.on("selection:cleared", handleSelectionCleared);

    return function cleanup() {
      canvas.off("selection:created", handleSelectionCreated);
      canvas.off("selection:cleared", handleSelectionCleared);
    };
  }, [canvas, setHasSelection]);

  return (
    <Grid
      gap={[0, 1]}
      sx={{
        width: "100%",
        // height: "100%",
        height: "calc(var(--vh, 1vh) * 100)",
        gridTemplateColumns: ["1fr 1fr 1fr 1fr 1fr 1fr"],
        gridTemplateRows: [
          isCreating || hasSelection ? "6fr 1fr auto" : "1fr auto",
          "4fr auto",
        ],
        gridTemplateAreas: [
          isCreating
            ? `"settings selection tools tools tools tools"
								"trash trash trash viewport viewport viewport"
								"shape shape shape shape shape shape"`
            : hasSelection
            ? `"settings settings tools tools tools tools"
								"trash trash shape shape viewport viewport"
								"selection selection selection selection selection selection"`
            : `"settings settings tools tools tools tools"
								"trash trash shape shape viewport viewport"`,
          `"settings settings selection selection tools tools"
								"trash trash shape shape viewport viewport"`,
        ],
        // "&>*": { zIndex: 2 },
        transition: "all ease-in 46ms",
        maxHeight: "inherit",
        "@media (max-height: 420px)": {
          // gridTemplateAreas: hasSelection
          //   ? `"settings settings tools tools tools tools"
          // "trash trash shape shape viewport viewport"
          // "selection selection selection selection selection selection"`
          //   : `"settings settings selection selection tools tools"
          // "trash trash shape shape viewport viewport"`,
          // gridTemplateRows: "2fr 1fr auto",
        },
      }}
      role="application"
    >
      <SettingsContainer />
      <SelectionContainer />
      <ToolsContainer />
      <SecondaryToolsContainer />
      <ShapesContainer
        onCreation={() => {
          setIsCreating(true);
        }}
        onCreationFinish={() => {
          setIsCreating(false);
        }}
      />
      <ViewportToolsContainer />
    </Grid>
  );
};

export default App;
