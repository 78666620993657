import { createAction } from "@reduxjs/toolkit";
import type { Action } from "redux";
import { StateWithHistory } from "redux-undo";
import type { EcogardenObject, EcogardenObjects } from "../lib/objects";
import type { Viewport } from "./viewport";

export enum Actions {
  clearCanvas = "CLEAR_CANVAS",
  loadDesign = "LOAD_DESIGN",
}

export type ClearCanvas = Action<Actions.clearCanvas>;

export interface LoadDesign extends Action<Actions.loadDesign> {
  readonly objects: readonly EcogardenObject[];
  readonly viewport: Viewport;
}

/**
 * Clear the fabric canvas
 */
export const clearCanvas = createAction<void>(Actions.clearCanvas);

/**
 * Load Design
 * - Objects
 * - Viewport
 */
export const loadDesign = createAction<{
  readonly objects: readonly EcogardenObjects[];
  readonly viewport: Viewport;
}>(Actions.loadDesign);
