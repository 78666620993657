import { Action, ActionCreator } from "redux";

export enum Actions {
  setSiteName = "SET_SITE_NAME",
  showDebug = "SHOW_DEBUG",
  hideDebug = "HIDE_DEBUG",
}

export interface SetSiteName extends Action<Actions.setSiteName> {
  readonly type: Actions.setSiteName;
  readonly name: string;
}

export const setSiteName: ActionCreator<SetSiteName> = (name: string) => ({
  type: Actions.setSiteName,
  name,
});

export interface ShowDebug extends Action<Actions.showDebug> {
  readonly type: Actions.showDebug;
}

export const showDebug: ActionCreator<ShowDebug> = () => ({
  type: Actions.showDebug,
});

export interface HideDebug extends Action<Actions.hideDebug> {
  readonly type: Actions.hideDebug;
}

export const hideDebug: ActionCreator<HideDebug> = () => ({
  type: Actions.hideDebug,
});
