/* eslint-disable max-lines-per-function */
import React, { useState } from "react";
import styled from "styled-components";
import emotionStyled from "@emotion/styled";

import { Input, ThemeUIStyleObject } from "theme-ui";

import CheckIcon from "./icons/check.svg";
import EditIcon from "./icons/edit-3.svg";
import CancelIcon from "./icons/x.svg";

import IconButton from "./buttons/IconButton";

const TextBox = styled.div`
	display: flex;
	align-items: center;
	grid-gap: 0.3em;

	white-space: nowrap;
	text-overflow: ellipsis;
`;

const Form = styled.form`
	width: 100%;
	border: 2pt solid var(--accent-color);
	border-radius: 0 0.6em;
	background-color: var(--background-color);

	box-shadow: 2pt 2pt 0 var(--accent-color-60);
	stroke: currentColor;
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: 10em fit-content(5em);

	:focus-within {
		border-radius: 0;
	}
`;

const Controls = styled.section`
	display: flex;
	align-items: center;
`;

const BoxControls = styled(Controls)`
	border-top-right-radius: 0.6em;
`;

type ControlButtonProps = {
	readonly color: string;
};

const ControlButton = emotionStyled(IconButton)`
  color: ${(props: ControlButtonProps): string =>
		props.color ?? "currentColor"};
`;

const TextContainer = styled.section`
	overflow: hidden;
	text-overflow: ellipsis;
`;

const Text: React.FunctionComponent<{
	readonly value: string;
	readonly button?: boolean;
	readonly onActivate: React.EventHandler<React.MouseEvent>;
	readonly placeholder?: string;
	readonly limit?: number | undefined;
	// eslint-disable-next-line complexity
}> = ({ value, button, onActivate, placeholder, limit, ...props }) => {
	const hasValue = !!value;

	return (
		<TextBox
			{...props}
			onClick={onActivate}
			style={{
				cursor: "pointer",
				filter: "greyscale(1)",
			}}
		>
			{limit ? (
				<TextContainer title={value}>
					{hasValue ? value.substring(0, limit) : placeholder}
					{value.length > limit ? "..." : ""}
				</TextContainer>
			) : (
				<TextContainer>{hasValue ? value : placeholder}</TextContainer>
			)}
			<Controls>
				{button && (
					<IconButton
						label="Edit"
						icon={EditIcon}
						variant="simple"
						sx={{ opacity: 0.5 }}
					/>
				)}
			</Controls>
		</TextBox>
	);
};

const EditText: React.FunctionComponent<{
	readonly onSubmit: React.EventHandler<React.FormEvent<HTMLFormElement>>;
	readonly onCancel: React.EventHandler<React.MouseEvent>;
	readonly value: string;
	readonly onChange: React.EventHandler<React.FormEvent>;
	readonly placeholder?: string;
	readonly formSx?: ThemeUIStyleObject;
}> = ({
	onSubmit,
	onCancel,
	placeholder,
	value,
	onChange,
	formSx,
	...props
}) => (
	<Form
		data-testid="EditText-form"
		onSubmit={onSubmit}
		sx={{ ...formSx, gridGap: 3 }}
	>
		<Input
			maxLength={255}
			placeholder={placeholder}
			value={value}
			onChange={onChange}
			spellCheck="false"
			{...props}
		/>
		<BoxControls sx={{ gap: 3 }}>
			<ControlButton
				label="Save"
				icon={CheckIcon}
				color="green"
				type="submit"
				variant="simple"
			/>
			<ControlButton
				label="Cancel"
				icon={CancelIcon}
				color="red"
				type="button"
				variant="simple"
				onClick={onCancel}
			/>
		</BoxControls>
	</Form>
);

export type Props = {
	readonly value: string;
	readonly isOpen?: boolean;
	readonly onSubmit?: (e: Pick<React.FormEvent, "preventDefault">) => void;
	readonly onCancel?: React.EventHandler<React.MouseEvent>;
	readonly onChange: React.EventHandler<React.FormEvent>;
	readonly placeholder?: string;
	readonly limit?: number | undefined;
	readonly formSx?: ThemeUIStyleObject;
};

// eslint-disable-next-line max-lines-per-function
const EditableText: React.FunctionComponent<Props> = ({
	onSubmit,
	onCancel,
	isOpen,
	value,
	placeholder,
	onChange,
	formSx,
	limit,
	...props
}) => {
	const [isEditing, setIsEditing] = useState(isOpen ?? false);

	const handleSubmit: (e: Pick<React.FormEvent, "preventDefault">) => void = (
		e
	) => {
		e.preventDefault();
		onSubmit && onSubmit(e);
		setIsEditing(false);
	};

	const handleCancel: React.EventHandler<React.MouseEvent> = (e) => {
		e.preventDefault();
		onCancel && onCancel(e);
		setIsEditing(false);
	};

	const handleActivate: React.EventHandler<React.MouseEvent> = (e) => {
		e.preventDefault();
		setIsEditing(true);
	};

	if (isEditing) {
		return (
			<EditText
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				onSubmit={handleSubmit}
				onCancel={handleCancel}
				formSx={formSx}
				{...props}
			/>
		);
	}

	return (
		<Text
			placeholder={placeholder}
			value={value}
			onActivate={handleActivate}
			button={true}
			limit={limit}
			{...props}
		/>
	);
};

export default EditableText;
