import type { Action, ActionCreator } from "redux";

type Panel = { readonly type: string };

/* Managing Panel state */

export enum Actions {
  open = "OPEN_PANEL",
  close = "CLOSE_PANEL",
  closeAll = "CLOSE_ALL_PANELS",
  closeTop = "CLOSE_TOP_PANEL",
}

export interface Open extends Action<Actions.open> {
  readonly key: string;
}

export const open: ActionCreator<Open> = (key: string) => ({
  type: Actions.open,
  key,
});

export interface Close extends Action<Actions.close> {
  readonly key: string;
}

export const close: ActionCreator<Close> = (key: string) => ({
  type: Actions.close,
  key,
});

export type CloseAll = Action<Actions.closeAll>;

export const closeAll: ActionCreator<CloseAll> = () => ({
  type: Actions.closeAll,
});

export type CloseTop = Action<Actions.closeTop>;

export const closeTop: ActionCreator<CloseTop> = (
  panels: readonly Panel[]
) => ({
  type: Actions.closeTop,
  panel: panels[panels.length - 1],
});
