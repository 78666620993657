import { createAction } from "@reduxjs/toolkit";
import { Point } from "../lib/vector";
import { OSMZoomLevel } from "../lib/zoom";

export enum Actions {
  zoom = "ZOOM",
  zoomTo = "ZOOM_TO",
}

/**
 * Set zoom level
 */
export const zoom = createAction<OSMZoomLevel>(Actions.zoom);
