import { NavLink } from "theme-ui";

import styled from "@emotion/styled";

export default styled(NavLink)`
  border: none;
  text-align: left;
  cursor: pointer;
  text-decoration: underline;
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 0.5em;
  appearance: none;
  line-height: 1.4;
  padding: 8px;

  :hover,
  :focus {
    background-color: var(--theme-ui-colors-accent-bg);
    box-shadow: 0 0 2pt 1pt var(--theme-ui-colors-accent), 0 0 5pt 2pt gold;
  }
`;
