import type { ActionCreator, Action } from "redux";

export enum Actions {
  setCanvas = "SET_CANVAS",
}

export interface SetCanvas extends Action<Actions.setCanvas> {
  readonly canvas: string;
}

/**
 * Set canvasId of the canvas element in the DOM
 */
export const setCanvas: ActionCreator<SetCanvas> = (canvasId: string) => ({
  type: Actions.setCanvas,
  canvas: canvasId,
});
