import { createAction } from "@reduxjs/toolkit";
import type { Layer } from "../shapes";

export enum Actions {
  setLock = "LAYER_SET_LOCK",
  setVisiblity = "LAYER_SET_VISIBILITY",
}

export const setLock = createAction<[Layer, boolean]>(Actions.setLock);
export const setVisiblity = createAction<[Layer, boolean]>(
  Actions.setVisiblity
);
