import React from "react";
import { ToastContainer } from "react-toastify";
import styled from "styled-components";
import { BaseModalBackground, ModalProvider } from "styled-react-modal";
import { Box, ThemeProvider } from "theme-ui";
import theme from "../theme";
import App from "./App";
import Canvas from "./canvas/Canvas";
import Debug from "./debug/Debug";
import ObjectsDebug from "./debug/ObjectsDebug";

export const SpecialModalBackground = styled(BaseModalBackground)`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 256;
	height: 100%;
	width: 100%;
	animation: ease-in 96ms overlayIn;
	opacity: 1;
	background-color: var(--theme-ui-colors-accent-bg-90);
	display: grid;
	align-items: center;
	justify-content: center;
`;

const AppContainer: React.FunctionComponent = () => {
	return (
		<ThemeProvider theme={theme}>
			<ModalProvider backgroundComponent={SpecialModalBackground}>
				<Box
					sx={{
						width: "100%",
						maxHeight: "100%",
					}}
				>
					<Debug />
					<ObjectsDebug />
					<ToastContainer sx={{ position: "fixed", zIndex: 8 }} theme="dark" />
					<App />
					<Canvas />
				</Box>
			</ModalProvider>
		</ThemeProvider>
	);
};

export default AppContainer;
