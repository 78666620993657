import { createAction } from "@reduxjs/toolkit";
import type { EcogardenObject, EcogardenObjects } from "../lib/objects";

export enum Actions {
  addObject = "ADD_OBJECT",
  addObjects = "ADD_OBJECTS",
  setObjects = "SET_OBJECTS",
  deleteObject = "DELETE_OBJECT",
  deleteObjects = "DELETE_OBJECTS",
  modifiedObject = "MODIFIED_OBJECT",
  sortObjects = "SORT_OBJECTS",
  setLabel = "SET_OBJECT_LABEL",
  setScale = "SET_OBJECT_SCALE",
}

export const addObject = createAction<Readonly<EcogardenObjects>>(
  Actions.addObject
);
export const addObjects = createAction<readonly Readonly<EcogardenObjects>[]>(
  Actions.addObjects
);

export const setObjects = createAction<readonly EcogardenObjects[]>(
  Actions.setObjects
);
export const sortObjects = createAction<void>(Actions.sortObjects);

export const deleteObject = createAction<string>(Actions.deleteObject);
export const deleteObjects = createAction<readonly string[]>(
  Actions.deleteObjects
);

export const modifiedObject = createAction<
  Partial<EcogardenObject> & { id: string }
>(Actions.modifiedObject);
