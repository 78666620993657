import type { Action, ActionCreator } from "redux";

export enum Actions {
  enablePan = "ENABLE_PAN",
  disablePan = "DISABLE_PAN",
  panToPoint = "PAN_TO_POINT",
  setPan = "SET_PAN",
}

export type EnablePan = Action<Actions.enablePan>;

export const enablePan: ActionCreator<EnablePan> = () => ({
  type: Actions.enablePan,
});

export type DisablePan = Action<Actions.disablePan>;

export const disablePan: ActionCreator<DisablePan> = () => ({
  type: Actions.disablePan,
});

export interface SetPan extends Action<Actions.setPan> {
  readonly pan: boolean;
}

export const setPan: ActionCreator<SetPan> = (pan: boolean) => ({
  type: Actions.setPan,
  pan,
});

export type PanActions = EnablePan | DisablePan;
