/** @jsxImportSource theme-ui */
import React from "react";
import styled from "styled-components";
import IconBlock from "./buttons/IconBlock";
import TextLinkButton from "./buttons/TextLinkButton";
// import Smile from "./icons/smile.svg";
import { Smile } from "@styled-icons/feather/Smile";

const FullTextLinkButton = styled(TextLinkButton)`
  width: 100%;
`;

const Feedback: React.FunctionComponent = () => {
  return (
    <FullTextLinkButton
      href="https://us5.list-manage.com/survey?u=cc7b4a08d12f0b3747b378adc&id=ff10c88f59&attribution=false"
      sx={{ fontWeight: "normal" }}
    >
      <IconBlock icon={Smile} /> Let us know how we&apos;re doing.
    </FullTextLinkButton>
  );
};

export default Feedback;
