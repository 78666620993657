/** @jsxImportSource theme-ui */
import Tippy, { TippyProps } from "@tippyjs/react";
import * as React from "react";
import { MouseEventHandler } from "react";
import Button from "./Button";
import IconBlock from "./IconBlock";
import type { StyledIcon, StyledIconProps } from "@styled-icons/styled-icon";
import { ThemeUIStyleObject } from "theme-ui";

type Properties = {
	readonly icon:
		| StyledIcon
		| React.FunctionComponent<React.SVGAttributes<SVGElement>>;
	readonly label: string;
	readonly size?: number;
	readonly primary?: boolean;
	readonly tippyProps?: Omit<TippyProps, "content" | "children">;
	readonly iconProps?: StyledIconProps;
	readonly frontIconProps?: StyledIconProps;
	readonly backIconProps?: StyledIconProps;
	readonly onClick?: MouseEventHandler<HTMLButtonElement>;
	readonly onMouseDown?: MouseEventHandler<HTMLButtonElement>;
	readonly type?: "button" | "submit" | "reset";
	readonly style?: Record<string, unknown>;
	readonly sx?: ThemeUIStyleObject;
	readonly variant?: "simple" | undefined;
};

// eslint-disable-next-line max-lines-per-function
const IconButton: React.FunctionComponent<Properties> = ({
	icon,
	label,
	size,
	style,
	tippyProps,
	iconProps,
	frontIconProps,
	backIconProps,
	onClick,
	// onMouseDown,
	sx,
	...properties
}) => {
	const Icon = icon;

	return (
		<Tippy
			content={label}
			delay={[500, 0]}
			appendTo={document.getElementById("tooltips-container") ?? undefined}
			{...tippyProps}
		>
			<Button
				variant="icon"
				type="button"
				aria-label={label}
				style={style}
				onClick={onClick}
				sx={sx}
				{...properties}
			>
				<IconBlock
					icon={Icon}
					size={size ?? 24}
					iconProps={iconProps}
					frontIconProps={frontIconProps}
					backIconProps={backIconProps}
				/>
			</Button>
		</Tippy>
	);
};

export default IconButton;
