import React from "react";
import { Box } from "theme-ui";
import TrashContainer from "./tools/TrashContainer";

const SecondaryToolsContainer: React.FunctionComponent = () => {
  return (
    <Box
      sx={{
        gridArea: "trash",
        alignSelf: "flex-end",
        pointerEvents: "none",

        zIndex: 2,
        position: "relative",
      }}
    >
      <TrashContainer />
    </Box>
  );
};

export default SecondaryToolsContainer;
