import type { ActionCreator, Action } from "redux";

export enum Actions {
  setMapLong = "SET_LONGITUDE",
  setMapLat = "SET_LATITUDE",
  moveMap = "MAP_MOVE",
}

export interface SetMapLong extends Action<Actions.setMapLong> {
  readonly lng: number;
}

export const setMapLongitude: ActionCreator<SetMapLong> = (
  longitude: number
) => ({
  type: Actions.setMapLong,
  lng: longitude,
});

export interface SetMapLat extends Action<Actions.setMapLat> {
  readonly lat: number;
}

export const setMapLatitude: ActionCreator<SetMapLat> = (latitude: number) => ({
  type: Actions.setMapLat,
  lat: latitude,
});

export interface MoveMap extends Action<Actions.moveMap> {
  readonly lat: number;
  readonly lng: number;
}

export const moveMap: ActionCreator<MoveMap> = (
  longitude: number,
  latitude: number
) => ({
  type: Actions.moveMap,
  lat: latitude,
  lng: longitude,
});
