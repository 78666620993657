/** @jsxImportSource theme-ui */

import { Grid, ThemeUIStyleObject } from "theme-ui";
import type { StyledIcon, StyledIconProps } from "@styled-icons/styled-icon";

type Properties = {
	readonly icon:
		| StyledIcon
		| React.FunctionComponent<React.SVGAttributes<SVGElement>>;
	readonly size?: number;
	readonly iconProps?: StyledIconProps | { sx: ThemeUIStyleObject };
	readonly frontIconProps?: StyledIconProps;
	readonly frontIconSx?: ThemeUIStyleObject;
	readonly backIconProps?: StyledIconProps;
	readonly backIconSx?: ThemeUIStyleObject;
};

// eslint-disable-next-line max-lines-per-function
const IconBlock: React.FunctionComponent<Properties> = ({
	icon,
	size,
	iconProps,
	frontIconProps,
	frontIconSx,
	backIconProps,
	backIconSx,
}) => {
	const Icon = icon;
	const iconSize = !size ? 24 : size;

	return (
		<Grid
			sx={{
				pointerEvents: "none",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Icon
				width={iconSize}
				height={iconSize}
				role="img"
				aria-hidden="true"
				sx={{
					stroke: "text",
					strokeWidth: 2,
					transform: "translate(-1px, -1px)",
					gridArea: "1 / 1 / 2 / 2",
					...frontIconSx,
				}}
				className="icon-button"
				{...iconProps}
				{...frontIconProps}
			/>
			<Icon
				width={iconSize}
				height={iconSize}
				role="img"
				aria-hidden="true"
				sx={{
					stroke: "var(--theme-ui-colors-background)",
					strokeWidth: 4,
					gridArea: "1 / 1 / 2 / 2",
					...backIconSx,
				}}
				className="icon-button--background"
				{...iconProps}
				{...backIconProps}
			/>
		</Grid>
	);
};

export default IconBlock;
