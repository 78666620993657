import React from "react";
import { Grid } from "theme-ui";
import ScaleLegend from "./canvas/ScaleLegend";
import FitToViewport from "./tools/FitToViewport";
import FullScreen from "./tools/FullScreen";

const ViewportToolsContainer: React.FunctionComponent = () => {
  return (
    <Grid
      sx={{
        alignItems: "flex-end",
        pointerEvents: "none",
        gridArea: "viewport",
        zIndex: 2,
        position: "relative",
      }}
    >
      <Grid
        sx={{
          gridAutoFlow: "column",
          gap: 0,
          margin: 1,
          justifySelf: "right",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          gridTemplateAreas: `"fit full"
															"scale scale"`,
        }}
      >
        <ScaleLegend />
        <FitToViewport />
        <FullScreen />
      </Grid>
    </Grid>
  );
};

export default ViewportToolsContainer;
