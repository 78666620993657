/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { Button, Grid, ThemeProvider } from "theme-ui";
import theme from "../theme";

export const EmbedContainer = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className="embed-container">
        <Grid
          className="gap-height"
          sx={{
            padding: "2em 0",
            justifyContent: "center",
            transition: "all ease-in 96ms",
          }}
        >
          <img
            src="https://ecogarden.design/svg/Ecogarden-10-06-2021_19_17_55.svg"
            alt="An Ecogarden design."
            width="1989.788"
            height="2094.003"
            sx={{
              width: ["240px"],
              height: "auto",
              display: ["none", "inline-block"],
              "@media(max-height: 620px)": { display: "none" },
            }}
            className="sample-design"
          />
          <Grid className="slide-in-top">
            <span sx={{ textAlign: "center" }}>
              Design your garden for <em>free</em>
            </span>
          </Grid>
          <Button variant="primary">Start Your Design</Button>
          <Grid
            className="slide-in-bottom"
            sx={{
              fontSize: "0.9em",
              justifyContent: "center",
              textAlign: "center",
              gridGap: "0",
            }}
          >
            <em>Powered by</em>
            <h2 sx={{ margin: "0.5em 0", fontSize: "1.2em" }}>
              <svg
                viewBox="0 0 64 64"
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                stroke="darkgreen"
                role="img"
                aria-hidden="true"
                sx={{ width: "24px", height: "auto" }}
              >
                <path
                  d="M165.893 120.827l19.653 4.196 2.098 10.378 4.527 12.697-8.612 12.477m28.375-60.174l-11.261 13.36-15.237 11.041m6.625 22.634l17.334-1.656 10.82-8.06m-9.826 21.64l-.994-13.359"
                  transform="matrix(1.31025 0 0 1.30318 -222.369 -142.863)"
                  fill="none"
                  stroke="#3e8042"
                ></path>
                <g fill="none" stroke="#334624">
                  <path
                    d="M173.512 101.174l24.069 2.871 5.852 21.64-31.467 19.322-12.808-9.606 14.354-34.227"
                    transform="matrix(1.31025 0 0 1.30318 -222.369 -142.863)"
                  ></path>
                  <path
                    d="M187.423 135.512l-2.429-10.489-19.653-4.306m19.653 4.526l15.126-11.482m-2.318-9.606l10.93-7.949 3.423 4.306-11.924 13.58m12.035-13.801l5.741 2.65.773 29.921-15.016-6.846"
                    transform="matrix(1.31025 0 0 1.30318 -222.369 -142.863)"
                  ></path>
                  <path
                    d="M171.966 145.117l-1.215 10.158 13.029 5.3 8.06-12.587-4.306-12.587m4.858 12.366l17.003-1.877 10.599-8.06-1.325-4.637m-34.889 27.603l1.214 6.183 25.395-7.287-.884-13.802m1.104 13.802l11.262 2.76 3.975-20.316-5.079-3.975"
                    transform="matrix(1.31025 0 0 1.30318 -222.369 -142.863)"
                  ></path>
                </g>
                <path
                  d="M170.42 154.833l-14.905 1.215-2.761-12.587 6.294-7.729m11.482 19.433l-5.41 14.353 15.127 6.514 4.858-8.833"
                  transform="matrix(1.31025 0 0 1.30318 -222.369 -142.863)"
                  fill="none"
                  stroke="#334624"
                ></path>
                <path
                  d="M165.231 120.496l19.984 4.416 2.65 10.6-15.568 9.605-13.139-10.047 6.073-14.574z"
                  fill="#0fb71b"
                  stroke="#334624"
                  transform="matrix(1.31025 0 0 1.30318 -222.369 -142.863)"
                ></path>
                <path
                  d="M173.843 101.064l-10.217 18.635 21.92 5.324 14.685-11.041-2.208-9.937-24.18-2.981"
                  fill="#18dd26"
                  stroke="#334624"
                  transform="matrix(1.31025 0 0 1.30318 -222.369 -142.863)"
                ></path>
                <path
                  d="M198.243 104.265l1.877 9.717 11.814-14.133-3.091-3.533-10.6 7.949"
                  fill="#17c823"
                  stroke="#334624"
                  transform="matrix(1.31025 0 0 1.30318 -222.369 -142.863)"
                ></path>
                <path
                  d="M212.155 100.18l6.183 2.981.331 29.48-15.236-6.735-2.981-12.035 11.703-13.691z"
                  fill="#1df52b"
                  stroke="#334624"
                  transform="matrix(1.37798 0 0 1.30318 -235.946 -142.863)"
                ></path>
                <path
                  d="M200.452 114.092l-14.795 10.71 1.987 9.716 15.678-8.612-2.87-11.814z"
                  fill="#1dc328"
                  stroke="#334624"
                  transform="matrix(1.31025 0 0 1.30318 -222.369 -142.863)"
                ></path>
                <path
                  d="M187.865 135.622l4.196 12.035-7.95 12.587-13.028-5.3 1.214-10.158 15.568-9.164z"
                  fill="#249e2c"
                  stroke="#334624"
                  transform="matrix(1.31025 0 0 1.30318 -222.369 -142.863)"
                ></path>
                <path
                  d="M187.755 135.401l4.637 12.035 17.003-1.656 10.599-8.171-1.104-5.189-15.457-6.845-15.678 9.826z"
                  fill="#2be037"
                  stroke="#334624"
                  transform="matrix(1.31025 0 0 1.30318 -222.369 -142.863)"
                ></path>
                <path
                  d="M192.281 147.105l-8.612 13.249 1.546 6.183 25.284-6.735-.773-14.022-17.445 1.325z"
                  fill="#1dc328"
                  stroke="#334624"
                  transform="matrix(1.31025 0 0 1.30318 -222.369 -142.863)"
                ></path>
                <path
                  d="M219.884 137.72l6.183 4.085-4.306 20.426-11.152-2.65-.662-14.022 9.937-7.839z"
                  fill="#18dd26"
                  stroke="#334624"
                  transform="matrix(1.31025 0 0 1.30318 -222.369 -142.863)"
                ></path>
                <path
                  d="M200.452 115.086l-14.464 10.157m1.104 10.71l4.637 10.82m11.483-20.757l15.347 6.735m-9.054 12.808l10.158-8.943"
                  fill="none"
                  stroke="#334624"
                  transform="matrix(1.31025 0 0 1.30318 -222.369 -142.863)"
                ></path>
                <path
                  d="M159.71 135.18l-6.845 8.392 3.091 12.255 15.347-1.104 1.215-9.606-12.808-9.937z"
                  fill="#0fb71b"
                  stroke="#334624"
                  transform="matrix(1.31025 0 0 1.30318 -222.369 -142.863)"
                ></path>
                <path
                  d="M156.067 155.827l-4.858 7.729 7.728 9.826 5.963-4.085 6.072-14.464-14.905.994z"
                  fill="#17c823"
                  stroke="#334624"
                  transform="matrix(1.31025 0 0 1.30318 -222.369 -142.863)"
                ></path>
                <path
                  d="M171.067 155.181l12.602 5.173 1.325 6.514-4.858 8.943-25.282-19.517 16.213-1.113z"
                  fill="#18dd26"
                  stroke="#334624"
                  transform="matrix(1.31025 0 0 1.30318 -222.369 -142.863)"
                ></path>
              </svg>
              <a
                href="https://ecogarden.design"
                sx={{ textDecoration: "none" }}
              >
                Ecogarden Design
              </a>
            </h2>
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
};
