import { createAction } from "@reduxjs/toolkit";
import { FabricZoomLevel } from "../lib/fabric/zoom";
import { Point } from "../lib/vector";

export enum Actions {
  updateViewport = "UPDATE_CANVAS_VIEWPORT",
  setTranslation = "SET_CANVAS_TRANSLATION",
  setZoom = "SET_ZOOM",
}

export type Viewport = readonly number[];

export const updateViewport = createAction<Viewport>(Actions.updateViewport);

export const setTranslation = createAction<Readonly<Point>>(
  Actions.setTranslation
);

export const setZoom = createAction<FabricZoomLevel>(Actions.setZoom);
